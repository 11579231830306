<template>
  <div>
    <ErrorBoundary>
      <Button
        data-test-id="navbar-team-event-request-button-desktop"
        class="hidden md:block py-1 hover:bg-teal-900"
        @click="toggleDateRequestSidebar(isEventPage, 'nav_bar')"
      >
        <slot />
      </Button>
      <Button
        data-test-id="navbar-team-event-request-button-mobile"
        class="flex bg-lime-200 text-primary items-center justify-center gap-2 w-screen -ml-4 mt-8 underline sm:hidden rounded-none shadow-none"
        @click="toggleDateRequestSidebar(isEventPage, 'mobile_bar')"
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-people-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"
            />
          </svg>
        </div>
        <span class="font-semibold">
          {{ $t('home.headerButtonTitle') }}
        </span>
      </Button>
    </ErrorBoundary>
  </div>
</template>
<script setup lang="ts">
const { toggleDateRequestSidebar } = useUiState()
const route = useRoute()

const isEventPage = computed(
  () => route.name?.split('___')[0] === 'e-eventPermalink'
)
</script>
